import PCCS_Color_Calc_R1 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R1";
import PCCS_Color_Calc_R6 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R6";
import PCCS_Color_Calc_R11 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R11";
import PCCS_Color_Calc_R16 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R16";
import PCCS_Color_Calc_R21 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R21";
import PCCS_Color_Calc_R26 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R26";
import PCCS_Color_Calc_R31 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R31";
import PCCS_Color_Calc_R36 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R36";
import PCCS_Color_Calc_R41 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R41";
import PCCS_Color_Calc_R46 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R46";
import PCCS_Color_Calc_R51 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R51";
import PCCS_Color_Calc_R56 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R56";
import PCCS_Color_Calc_R61 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R61";
import PCCS_Color_Calc_R66 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R66";
import PCCS_Color_Calc_R71 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R71";
import PCCS_Color_Calc_R76 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R76";
import PCCS_Color_Calc_R81 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R81";
import PCCS_Color_Calc_R86 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R86";
import PCCS_Color_Calc_R91 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R91";
import PCCS_Color_Calc_R96 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R96";
import PCCS_Color_Calc_R101 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R101";
import PCCS_Color_Calc_R106 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R106";
import PCCS_Color_Calc_R111 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R111";
import PCCS_Color_Calc_R116 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R116";
import PCCS_Color_Calc_R121 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R121";
import PCCS_Color_Calc_R126 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R126";
import PCCS_Color_Calc_R131 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R131";
import PCCS_Color_Calc_R136 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R136";
import PCCS_Color_Calc_R141 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R141";
import PCCS_Color_Calc_R146 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R146";
import PCCS_Color_Calc_R151 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R151";
import PCCS_Color_Calc_R156 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R156";
import PCCS_Color_Calc_R161 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R161";
import PCCS_Color_Calc_R166 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R166";
import PCCS_Color_Calc_R171 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R171";
import PCCS_Color_Calc_R176 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R176";
import PCCS_Color_Calc_R181 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R181";
import PCCS_Color_Calc_R186 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R186";
import PCCS_Color_Calc_R191 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R191";
import PCCS_Color_Calc_R196 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R196";
import PCCS_Color_Calc_R201 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R201";
import PCCS_Color_Calc_R206 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R206";
import PCCS_Color_Calc_R211 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R211";
import PCCS_Color_Calc_R216 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R216";
import PCCS_Color_Calc_R221 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R221";
import PCCS_Color_Calc_R226 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R226";
import PCCS_Color_Calc_R231 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R231";
import PCCS_Color_Calc_R236 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R236";
import PCCS_Color_Calc_R241 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R241";
import PCCS_Color_Calc_R246 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R246";
import PCCS_Color_Calc_R251 from "@/utils/munsell/excel/js/PCCS_Color_Calc_R251";

function nearbyValue(value, interval = 5, shift = 1, max = 255) {
    value -= shift
    if (value < interval) {
        return shift;
    }

    if (Math.floor((max - shift) / interval) * interval <= value) {
        return Math.floor((max - shift) / interval) * interval + shift;
    }
    const base = Math.floor(value / interval) * interval;
    if (value - base <= interval / 2) {
        return base + shift;
    } else {
        return base + interval + shift;
    }
}

export function getMunsell(RGB) {
    let R = nearbyValue(RGB[0])
    let mun
    let muns
    muns = [
        PCCS_Color_Calc_R1, PCCS_Color_Calc_R6,
        PCCS_Color_Calc_R11, PCCS_Color_Calc_R16,
        PCCS_Color_Calc_R21, PCCS_Color_Calc_R26,
        PCCS_Color_Calc_R31, PCCS_Color_Calc_R36,
        PCCS_Color_Calc_R41, PCCS_Color_Calc_R46,
        PCCS_Color_Calc_R51, PCCS_Color_Calc_R56,
        PCCS_Color_Calc_R61, PCCS_Color_Calc_R66,
        PCCS_Color_Calc_R71, PCCS_Color_Calc_R76,
        PCCS_Color_Calc_R81, PCCS_Color_Calc_R86,
        PCCS_Color_Calc_R91, PCCS_Color_Calc_R96,
        PCCS_Color_Calc_R101, PCCS_Color_Calc_R106,
        PCCS_Color_Calc_R111, PCCS_Color_Calc_R116,
        PCCS_Color_Calc_R121, PCCS_Color_Calc_R126,
        PCCS_Color_Calc_R131, PCCS_Color_Calc_R136,
        PCCS_Color_Calc_R141, PCCS_Color_Calc_R146,
        PCCS_Color_Calc_R151, PCCS_Color_Calc_R156,
        PCCS_Color_Calc_R161, PCCS_Color_Calc_R166,
        PCCS_Color_Calc_R171, PCCS_Color_Calc_R176,
        PCCS_Color_Calc_R181, PCCS_Color_Calc_R186,
        PCCS_Color_Calc_R191, PCCS_Color_Calc_R196,
        PCCS_Color_Calc_R201, PCCS_Color_Calc_R206,
        PCCS_Color_Calc_R211, PCCS_Color_Calc_R216,
        PCCS_Color_Calc_R221, PCCS_Color_Calc_R226,
        PCCS_Color_Calc_R231, PCCS_Color_Calc_R236,
        PCCS_Color_Calc_R241, PCCS_Color_Calc_R246,
        PCCS_Color_Calc_R251,
    ];
    mun = muns[Math.floor((R - 1) / 5)]
    return mun[nearbyValue(RGB[1])][nearbyValue(RGB[2])]
}
