const PCCS_Color_Calc_R1 = {
  1: {
    1: "N",
    6: "PB",
    11: "PB",
    16: "PB",
    21: "PB",
    26: "PB",
    31: "",
    36: "",
    41: "",
    46: "",
    51: "",
    56: "",
    61: "",
    66: "",
    71: "",
    76: "",
    81: "",
    86: "",
    91: "",
    96: "",
    101: "",
    106: "",
    111: "",
    116: "",
    121: "",
    126: "",
    131: "",
    136: "",
    141: "",
    146: "",
    151: "",
    156: "",
    161: "",
    166: "",
    171: "",
    176: "",
    181: "",
    186: "",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  6: {
    1: "GY",
    6: "BG",
    11: "B",
    16: "PB",
    21: "PB",
    26: "PB",
    31: "PB",
    36: "PB",
    41: "PB",
    46: "PB",
    51: "PB",
    56: "PB",
    61: "PB",
    66: "PB",
    71: "PB",
    76: "PB",
    81: "PB",
    86: "",
    91: "",
    96: "",
    101: "",
    106: "",
    111: "",
    116: "",
    121: "",
    126: "",
    131: "",
    136: "",
    141: "",
    146: "",
    151: "",
    156: "",
    161: "",
    166: "",
    171: "",
    176: "",
    181: "",
    186: "",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  11: {
    1: "GY",
    6: "G",
    11: "BG",
    16: "B",
    21: "PB",
    26: "PB",
    31: "PB",
    36: "PB",
    41: "PB",
    46: "PB",
    51: "PB",
    56: "PB",
    61: "PB",
    66: "PB",
    71: "PB",
    76: "PB",
    81: "PB",
    86: "PB",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "",
    116: "",
    121: "",
    126: "",
    131: "",
    136: "",
    141: "",
    146: "",
    151: "",
    156: "",
    161: "",
    166: "",
    171: "",
    176: "",
    181: "",
    186: "",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  16: {
    1: "GY",
    6: "G",
    11: "G",
    16: "BG",
    21: "B",
    26: "B",
    31: "PB",
    36: "PB",
    41: "PB",
    46: "PB",
    51: "PB",
    56: "PB",
    61: "PB",
    66: "PB",
    71: "PB",
    76: "PB",
    81: "PB",
    86: "PB",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "",
    141: "",
    146: "",
    151: "",
    156: "",
    161: "",
    166: "",
    171: "",
    176: "",
    181: "",
    186: "",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  21: {
    1: "GY",
    6: "GY",
    11: "G",
    16: "G",
    21: "BG",
    26: "B",
    31: "B",
    36: "PB",
    41: "PB",
    46: "PB",
    51: "PB",
    56: "PB",
    61: "PB",
    66: "PB",
    71: "PB",
    76: "PB",
    81: "PB",
    86: "PB",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "",
    166: "",
    171: "",
    176: "",
    181: "",
    186: "",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  26: {
    1: "GY",
    6: "GY",
    11: "G",
    16: "G",
    21: "BG",
    26: "BG",
    31: "B",
    36: "B",
    41: "PB",
    46: "PB",
    51: "PB",
    56: "PB",
    61: "PB",
    66: "PB",
    71: "PB",
    76: "PB",
    81: "PB",
    86: "PB",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "",
    161: "",
    166: "",
    171: "",
    176: "",
    181: "",
    186: "",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  31: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "G",
    21: "G",
    26: "BG",
    31: "BG",
    36: "BG",
    41: "B",
    46: "B",
    51: "PB",
    56: "PB",
    61: "PB",
    66: "PB",
    71: "PB",
    76: "PB",
    81: "PB",
    86: "PB",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "",
    151: "",
    156: "",
    161: "",
    166: "",
    171: "",
    176: "",
    181: "",
    186: "",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  36: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "G",
    21: "G",
    26: "G",
    31: "BG",
    36: "BG",
    41: "BG",
    46: "B",
    51: "B",
    56: "PB",
    61: "PB",
    66: "PB",
    71: "PB",
    76: "PB",
    81: "PB",
    86: "PB",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "",
    161: "",
    166: "",
    171: "",
    176: "",
    181: "",
    186: "",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  41: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "G",
    21: "G",
    26: "G",
    31: "G",
    36: "BG",
    41: "BG",
    46: "BG",
    51: "B",
    56: "B",
    61: "B",
    66: "PB",
    71: "PB",
    76: "PB",
    81: "PB",
    86: "PB",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "",
    181: "",
    186: "",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  46: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "G",
    26: "G",
    31: "G",
    36: "G",
    41: "BG",
    46: "BG",
    51: "BG",
    56: "B",
    61: "B",
    66: "B",
    71: "PB",
    76: "PB",
    81: "PB",
    86: "PB",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  51: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "G",
    26: "G",
    31: "G",
    36: "G",
    41: "G",
    46: "BG",
    51: "BG",
    56: "BG",
    61: "B",
    66: "B",
    71: "B",
    76: "PB",
    81: "PB",
    86: "PB",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  56: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "G",
    26: "G",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "BG",
    56: "BG",
    61: "BG",
    66: "B",
    71: "B",
    76: "B",
    81: "B",
    86: "PB",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "",
    191: "",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  61: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "G",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "BG",
    56: "BG",
    61: "BG",
    66: "BG",
    71: "B",
    76: "B",
    81: "B",
    86: "B",
    91: "PB",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "",
    201: "",
    206: "",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  66: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "G",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "BG",
    61: "BG",
    66: "BG",
    71: "BG",
    76: "B",
    81: "B",
    86: "B",
    91: "B",
    96: "PB",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  71: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "G",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "BG",
    66: "BG",
    71: "BG",
    76: "BG",
    81: "B",
    86: "B",
    91: "B",
    96: "B",
    101: "PB",
    106: "PB",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  76: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "G",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "BG",
    71: "BG",
    76: "BG",
    81: "BG",
    86: "B",
    91: "B",
    96: "B",
    101: "B",
    106: "B",
    111: "PB",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "PB",
    241: "",
    246: "",
    251: "",
  },
  81: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "G",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "BG",
    76: "BG",
    81: "BG",
    86: "BG",
    91: "B",
    96: "B",
    101: "B",
    106: "B",
    111: "B",
    116: "PB",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  86: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "G",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "BG",
    81: "BG",
    86: "BG",
    91: "BG",
    96: "B",
    101: "B",
    106: "B",
    111: "B",
    116: "B",
    121: "PB",
    126: "PB",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  91: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "BG",
    81: "BG",
    86: "BG",
    91: "BG",
    96: "BG",
    101: "B",
    106: "B",
    111: "B",
    116: "B",
    121: "B",
    126: "B",
    131: "PB",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  96: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "BG",
    86: "BG",
    91: "BG",
    96: "BG",
    101: "BG",
    106: "B",
    111: "B",
    116: "B",
    121: "B",
    126: "B",
    131: "B",
    136: "PB",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  101: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "BG",
    91: "BG",
    96: "BG",
    101: "BG",
    106: "BG",
    111: "B",
    116: "B",
    121: "B",
    126: "B",
    131: "B",
    136: "B",
    141: "PB",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  106: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "BG",
    96: "BG",
    101: "BG",
    106: "BG",
    111: "BG",
    116: "B",
    121: "B",
    126: "B",
    131: "B",
    136: "B",
    141: "B",
    146: "PB",
    151: "PB",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "PB",
    241: "PB",
    246: "",
    251: "",
  },
  111: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "BG",
    101: "BG",
    106: "BG",
    111: "BG",
    116: "BG",
    121: "B",
    126: "B",
    131: "B",
    136: "B",
    141: "B",
    146: "B",
    151: "B",
    156: "PB",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "PB",
    241: "PB",
    246: "PB",
    251: "PB",
  },
  116: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "G",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "BG",
    106: "BG",
    111: "BG",
    116: "BG",
    121: "BG",
    126: "B",
    131: "B",
    136: "B",
    141: "B",
    146: "B",
    151: "B",
    156: "B",
    161: "PB",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "PB",
    241: "PB",
    246: "PB",
    251: "",
  },
  121: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "BG",
    106: "BG",
    111: "BG",
    116: "BG",
    121: "BG",
    126: "BG",
    131: "B",
    136: "B",
    141: "B",
    146: "B",
    151: "B",
    156: "B",
    161: "B",
    166: "PB",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  126: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "BG",
    111: "BG",
    116: "BG",
    121: "BG",
    126: "BG",
    131: "BG",
    136: "B",
    141: "B",
    146: "B",
    151: "B",
    156: "B",
    161: "B",
    166: "B",
    171: "PB",
    176: "PB",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  131: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "BG",
    116: "BG",
    121: "BG",
    126: "BG",
    131: "BG",
    136: "BG",
    141: "B",
    146: "B",
    151: "B",
    156: "B",
    161: "B",
    166: "B",
    171: "B",
    176: "B",
    181: "PB",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  136: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "BG",
    121: "BG",
    126: "BG",
    131: "BG",
    136: "BG",
    141: "BG",
    146: "B",
    151: "B",
    156: "B",
    161: "B",
    166: "B",
    171: "B",
    176: "B",
    181: "B",
    186: "PB",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "PB",
    241: "PB",
    246: "",
    251: "",
  },
  141: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "BG",
    126: "BG",
    131: "BG",
    136: "BG",
    141: "BG",
    146: "BG",
    151: "B",
    156: "B",
    161: "B",
    166: "B",
    171: "B",
    176: "B",
    181: "B",
    186: "B",
    191: "PB",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "PB",
    241: "PB",
    246: "PB",
    251: "PB",
  },
  146: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "BG",
    126: "BG",
    131: "BG",
    136: "BG",
    141: "BG",
    146: "BG",
    151: "BG",
    156: "B",
    161: "B",
    166: "B",
    171: "B",
    176: "B",
    181: "B",
    186: "B",
    191: "B",
    196: "PB",
    201: "PB",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "PB",
    241: "PB",
    246: "PB",
    251: "PB",
  },
  151: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "BG",
    131: "BG",
    136: "BG",
    141: "BG",
    146: "BG",
    151: "BG",
    156: "BG",
    161: "B",
    166: "B",
    171: "B",
    176: "B",
    181: "B",
    186: "B",
    191: "B",
    196: "B",
    201: "B",
    206: "PB",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "PB",
    241: "PB",
    246: "PB",
    251: "PB",
  },
  156: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "BG",
    136: "BG",
    141: "BG",
    146: "BG",
    151: "BG",
    156: "BG",
    161: "BG",
    166: "B",
    171: "B",
    176: "B",
    181: "B",
    186: "B",
    191: "B",
    196: "B",
    201: "B",
    206: "B",
    211: "PB",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  161: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "BG",
    141: "BG",
    146: "BG",
    151: "BG",
    156: "BG",
    161: "BG",
    166: "BG",
    171: "B",
    176: "B",
    181: "B",
    186: "B",
    191: "B",
    196: "B",
    201: "B",
    206: "B",
    211: "B",
    216: "PB",
    221: "PB",
    226: "PB",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  166: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "BG",
    146: "BG",
    151: "BG",
    156: "BG",
    161: "BG",
    166: "BG",
    171: "BG",
    176: "B",
    181: "B",
    186: "B",
    191: "B",
    196: "B",
    201: "B",
    206: "B",
    211: "B",
    216: "B",
    221: "PB",
    226: "PB",
    231: "PB",
    236: "PB",
    241: "",
    246: "",
    251: "",
  },
  171: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "G",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "BG",
    146: "BG",
    151: "BG",
    156: "BG",
    161: "BG",
    166: "BG",
    171: "BG",
    176: "BG",
    181: "B",
    186: "B",
    191: "B",
    196: "B",
    201: "B",
    206: "B",
    211: "B",
    216: "B",
    221: "B",
    226: "B",
    231: "PB",
    236: "PB",
    241: "PB",
    246: "",
    251: "",
  },
  176: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "BG",
    151: "BG",
    156: "BG",
    161: "BG",
    166: "BG",
    171: "BG",
    176: "BG",
    181: "BG",
    186: "B",
    191: "B",
    196: "B",
    201: "B",
    206: "B",
    211: "B",
    216: "B",
    221: "B",
    226: "B",
    231: "B",
    236: "PB",
    241: "PB",
    246: "PB",
    251: "PB",
  },
  181: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "BG",
    156: "BG",
    161: "BG",
    166: "BG",
    171: "BG",
    176: "BG",
    181: "BG",
    186: "BG",
    191: "B",
    196: "B",
    201: "B",
    206: "B",
    211: "B",
    216: "B",
    221: "B",
    226: "B",
    231: "B",
    236: "B",
    241: "PB",
    246: "PB",
    251: "PB",
  },
  186: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "BG",
    161: "BG",
    166: "BG",
    171: "BG",
    176: "BG",
    181: "BG",
    186: "BG",
    191: "BG",
    196: "B",
    201: "B",
    206: "B",
    211: "B",
    216: "B",
    221: "B",
    226: "B",
    231: "B",
    236: "B",
    241: "B",
    246: "PB",
    251: "PB",
  },
  191: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "BG",
    166: "BG",
    171: "BG",
    176: "BG",
    181: "BG",
    186: "BG",
    191: "BG",
    196: "BG",
    201: "B",
    206: "B",
    211: "B",
    216: "B",
    221: "B",
    226: "B",
    231: "B",
    236: "B",
    241: "B",
    246: "",
    251: "",
  },
  196: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "BG",
    166: "BG",
    171: "BG",
    176: "BG",
    181: "BG",
    186: "BG",
    191: "BG",
    196: "BG",
    201: "BG",
    206: "B",
    211: "B",
    216: "B",
    221: "B",
    226: "B",
    231: "B",
    236: "B",
    241: "B",
    246: "B",
    251: "",
  },
  201: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "BG",
    171: "BG",
    176: "BG",
    181: "BG",
    186: "BG",
    191: "BG",
    196: "BG",
    201: "BG",
    206: "BG",
    211: "B",
    216: "B",
    221: "B",
    226: "B",
    231: "B",
    236: "B",
    241: "B",
    246: "B",
    251: "B",
  },
  206: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "G",
    171: "BG",
    176: "BG",
    181: "BG",
    186: "BG",
    191: "BG",
    196: "BG",
    201: "BG",
    206: "BG",
    211: "BG",
    216: "B",
    221: "B",
    226: "B",
    231: "B",
    236: "B",
    241: "B",
    246: "B",
    251: "B",
  },
  211: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "G",
    171: "G",
    176: "BG",
    181: "BG",
    186: "BG",
    191: "BG",
    196: "BG",
    201: "BG",
    206: "BG",
    211: "BG",
    216: "BG",
    221: "B",
    226: "B",
    231: "B",
    236: "B",
    241: "B",
    246: "B",
    251: "B",
  },
  216: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "G",
    171: "G",
    176: "G",
    181: "BG",
    186: "BG",
    191: "BG",
    196: "BG",
    201: "BG",
    206: "BG",
    211: "BG",
    216: "BG",
    221: "BG",
    226: "B",
    231: "B",
    236: "B",
    241: "B",
    246: "B",
    251: "B",
  },
  221: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "G",
    171: "G",
    176: "G",
    181: "BG",
    186: "BG",
    191: "BG",
    196: "BG",
    201: "BG",
    206: "BG",
    211: "BG",
    216: "BG",
    221: "BG",
    226: "BG",
    231: "B",
    236: "B",
    241: "B",
    246: "",
    251: "",
  },
  226: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "G",
    171: "G",
    176: "G",
    181: "G",
    186: "BG",
    191: "BG",
    196: "BG",
    201: "BG",
    206: "BG",
    211: "BG",
    216: "BG",
    221: "",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  231: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "GY",
    41: "G",
    46: "G",
    51: "G",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "G",
    171: "G",
    176: "G",
    181: "G",
    186: "G",
    191: "BG",
    196: "BG",
    201: "BG",
    206: "BG",
    211: "BG",
    216: "BG",
    221: "BG",
    226: "",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  236: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "",
    41: "",
    46: "",
    51: "",
    56: "G",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "",
    136: "G",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "G",
    171: "G",
    176: "G",
    181: "G",
    186: "G",
    191: "G",
    196: "BG",
    201: "BG",
    206: "BG",
    211: "BG",
    216: "BG",
    221: "BG",
    226: "BG",
    231: "",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  241: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "",
    41: "",
    46: "",
    51: "",
    56: "",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "G",
    171: "G",
    176: "G",
    181: "G",
    186: "G",
    191: "G",
    196: "G",
    201: "BG",
    206: "BG",
    211: "BG",
    216: "BG",
    221: "BG",
    226: "BG",
    231: "BG",
    236: "",
    241: "",
    246: "",
    251: "",
  },
  246: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "",
    41: "",
    46: "",
    51: "",
    56: "",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "",
    141: "G",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "G",
    171: "G",
    176: "G",
    181: "G",
    186: "G",
    191: "G",
    196: "G",
    201: "G",
    206: "BG",
    211: "BG",
    216: "BG",
    221: "BG",
    226: "BG",
    231: "BG",
    236: "BG",
    241: "",
    246: "",
    251: "",
  },
  251: {
    1: "GY",
    6: "GY",
    11: "GY",
    16: "GY",
    21: "GY",
    26: "GY",
    31: "GY",
    36: "",
    41: "",
    46: "",
    51: "",
    56: "",
    61: "G",
    66: "G",
    71: "G",
    76: "G",
    81: "G",
    86: "G",
    91: "G",
    96: "G",
    101: "G",
    106: "G",
    111: "G",
    116: "G",
    121: "G",
    126: "G",
    131: "G",
    136: "G",
    141: "",
    146: "G",
    151: "G",
    156: "G",
    161: "G",
    166: "G",
    171: "G",
    176: "G",
    181: "G",
    186: "G",
    191: "G",
    196: "G",
    201: "G",
    206: "BG",
    211: "BG",
    216: "BG",
    221: "BG",
    226: "BG",
    231: "BG",
    236: "BG",
    241: "BG",
    246: "",
    251: "",
  }
}

export default PCCS_Color_Calc_R1
