<template>
  <div></div>
</template>

<script>
  import * as THREE from 'three'
  import { CataractShader } from '../shaders/CataractShader'
  import { Dichromats } from '../shaders/Dichromats'
  import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
  import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js'

  export default {
    name: 'OffscreenCanvas',

    data: () => ({
      shaderPass: undefined,
      removePass: undefined,
      mesh: undefined,
      geometry: undefined,
      composer: undefined,
      scene: undefined,
      renderer: undefined,
      offcanvas: undefined,
      width: 0,
      height: 0,
      isMounted: false
    }),
    props: [ 'mode' ],
    mounted: function () {
      console.log(this.$options._componentTag+this.mode)
      this.isMounted = true
    },
    watch:{
    },
    computed: {
    },
    methods: {
      updateImage(
              width,
              height,
              renderPass
        ){
        console.log(this.$options._componentTag+this.mode+" updateImage")

        if(!this.isMounted){
          return
        }

        this.width = width
        this.height = height

        if(!this.offcanvas){
          this.offcanvas  = document.createElement('canvas')
        }
        if(this.composer){
          this.composer.removePass(this.shaderPass)
          this.composer.removePass(this.rednerPass)
          delete this.composer
          this.composer = null
        }

        if(this.shaderPass){
          delete this.shaderPass
          this.shaderPass = null
        }
        if(this.renderer){
          this.renderer.dispose()
          delete this.renderer
          this.renderer = null
        }

        this.offcanvas.height = width
        this.offcanvas.width =  height
        this.renderer = new THREE.WebGLRenderer({ canvas: this.offcanvas, preserveDrawingBuffer: true })
        this.renderer.setClearColor(0xffffff, 1)
        this.renderer.setSize(width, height)
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.composer = new EffectComposer(this.renderer)
        this.composer.addPass(renderPass)

        var shader;
        if(this.mode == 3){
          shader = new CataractShader()
        }
        else{
          shader = new Dichromats()
          shader.uniforms.mode = { type: 'i', value: this.mode }
          shader.uniforms.fRario = { type: 'f', value: 100/100 }
        }

        this.shaderPass = new ShaderPass(shader)
        this.composer.addPass(this.shaderPass)

        this.render()

      },
      // Threejsレンダリング
      render () {
        console.log(this.$options._componentTag+this.mode+" render")
        this.composer.render()
      },
      onMouseClick(x, y){
        console.log(this.$options._componentTag+this.mode+" onMouseClick" + " x="+ x+ " y=" + y)

        // 指定座標から幅1,高さ1のImageDataオブジェクトの取得。
        var srcCanvas = this.renderer.domElement;
        var canvas = document.createElement('canvas')
        canvas.height = this.height
        canvas.width = this.width
        const ctx = canvas.getContext('2d')
        ctx.drawImage(srcCanvas, 0, 0, 
                srcCanvas.width, srcCanvas.height, 
                0, 0, 
                this.width, this.height);

        var imageData = ctx.getImageData(x, y, 1, 1)
        canvas = null
        return         {
          r:imageData.data[0],
          g:imageData.data[1],
          b:imageData.data[2],
          a:imageData.data[3],
         }
      }
    }
  }
</script>
