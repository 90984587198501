const PCCS_Color_Calc_R56 = {
    1: {1: "",
6: "YR",
11: "YR",
16: "R",
21: "R",
26: "R",
31: "R",
36: "RP",
41: "RP",
46: "RP",
51: "RP",
56: "RP",
61: "P",
66: "P",
71: "P",
76: "P",
81: "P",
86: "P",
91: "P",
96: "P",
101: "P",
106: "P",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
6: {1: "YR",
6: "YR",
11: "YR",
16: "R",
21: "R",
26: "R",
31: "R",
36: "RP",
41: "RP",
46: "RP",
51: "RP",
56: "RP",
61: "P",
66: "P",
71: "P",
76: "P",
81: "P",
86: "P",
91: "P",
96: "P",
101: "P",
106: "P",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
11: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "R",
26: "R",
31: "R",
36: "RP",
41: "RP",
46: "RP",
51: "RP",
56: "P",
61: "P",
66: "P",
71: "P",
76: "P",
81: "P",
86: "P",
91: "P",
96: "P",
101: "P",
106: "P",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
16: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "R",
26: "R",
31: "R",
36: "R",
41: "RP",
46: "RP",
51: "RP",
56: "P",
61: "P",
66: "P",
71: "P",
76: "P",
81: "P",
86: "P",
91: "P",
96: "P",
101: "P",
106: "P",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
21: {1: "",
6: "",
11: "YR",
16: "YR",
21: "YR",
26: "R",
31: "R",
36: "R",
41: "RP",
46: "RP",
51: "RP",
56: "P",
61: "P",
66: "P",
71: "P",
76: "P",
81: "P",
86: "P",
91: "P",
96: "P",
101: "P",
106: "P",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
26: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "R",
36: "R",
41: "RP",
46: "RP",
51: "RP",
56: "P",
61: "P",
66: "P",
71: "P",
76: "P",
81: "P",
86: "P",
91: "P",
96: "P",
101: "P",
106: "P",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
31: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "R",
36: "R",
41: "RP",
46: "RP",
51: "RP",
56: "P",
61: "P",
66: "P",
71: "P",
76: "P",
81: "P",
86: "P",
91: "P",
96: "P",
101: "P",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
36: {1: "Y",
6: "Y",
11: "Y",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "R",
41: "R",
46: "RP",
51: "RP",
56: "P",
61: "P",
66: "P",
71: "P",
76: "P",
81: "P",
86: "P",
91: "P",
96: "P",
101: "P",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
41: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "YR",
36: "YR",
41: "R",
46: "RP",
51: "RP",
56: "P",
61: "P",
66: "P",
71: "P",
76: "P",
81: "P",
86: "P",
91: "P",
96: "P",
101: "PB",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
46: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "YR",
46: "R",
51: "RP",
56: "P",
61: "P",
66: "P",
71: "P",
76: "P",
81: "P",
86: "P",
91: "PB",
96: "PB",
101: "PB",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
51: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "R",
56: "P",
61: "P",
66: "P",
71: "P",
76: "PB",
81: "PB",
86: "PB",
91: "PB",
96: "PB",
101: "PB",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
56: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "N",
61: "PB",
66: "PB",
71: "PB",
76: "PB",
81: "PB",
86: "PB",
91: "PB",
96: "PB",
101: "PB",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "",
241: "",
246: "",
251: "",
},
61: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "BG",
66: "PB",
71: "PB",
76: "PB",
81: "PB",
86: "PB",
91: "PB",
96: "PB",
101: "PB",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "",
251: "",
},
66: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "BG",
71: "B",
76: "PB",
81: "PB",
86: "PB",
91: "PB",
96: "PB",
101: "PB",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "",
246: "",
251: "",
},
71: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "BG",
76: "B",
81: "B",
86: "PB",
91: "PB",
96: "PB",
101: "PB",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "",
241: "",
246: "",
251: "",
},
76: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "BG",
76: "BG",
81: "B",
86: "B",
91: "PB",
96: "PB",
101: "PB",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
81: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "BG",
81: "BG",
86: "B",
91: "B",
96: "B",
101: "PB",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
86: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "BG",
86: "BG",
91: "B",
96: "B",
101: "B",
106: "PB",
111: "PB",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "",
236: "",
241: "",
246: "",
251: "",
},
91: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "BG",
91: "BG",
96: "B",
101: "B",
106: "B",
111: "B",
116: "PB",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "",
246: "",
251: "",
},
96: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "BG",
91: "BG",
96: "BG",
101: "B",
106: "B",
111: "B",
116: "B",
121: "PB",
126: "PB",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
101: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "BG",
96: "BG",
101: "BG",
106: "B",
111: "B",
116: "B",
121: "B",
126: "B",
131: "PB",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "",
251: "",
},
106: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "BG",
101: "BG",
106: "BG",
111: "BG",
116: "B",
121: "B",
126: "B",
131: "B",
136: "PB",
141: "PB",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
111: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "BG",
106: "BG",
111: "BG",
116: "BG",
121: "B",
126: "B",
131: "B",
136: "B",
141: "B",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
116: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "BG",
106: "BG",
111: "BG",
116: "BG",
121: "BG",
126: "B",
131: "B",
136: "B",
141: "B",
146: "B",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "",
241: "",
246: "",
251: "",
},
121: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "BG",
111: "BG",
116: "BG",
121: "BG",
126: "BG",
131: "B",
136: "B",
141: "B",
146: "B",
151: "B",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
126: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "BG",
116: "BG",
121: "BG",
126: "BG",
131: "BG",
136: "B",
141: "B",
146: "B",
151: "B",
156: "B",
161: "B",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "",
236: "",
241: "",
246: "",
251: "",
},
131: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "BG",
121: "BG",
126: "BG",
131: "BG",
136: "BG",
141: "B",
146: "B",
151: "B",
156: "B",
161: "B",
166: "B",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "",
246: "",
251: "",
},
136: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "BG",
126: "BG",
131: "BG",
136: "BG",
141: "BG",
146: "B",
151: "B",
156: "B",
161: "B",
166: "B",
171: "B",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "",
},
141: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "BG",
126: "BG",
131: "BG",
136: "BG",
141: "BG",
146: "BG",
151: "B",
156: "B",
161: "B",
166: "B",
171: "B",
176: "B",
181: "B",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
146: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "BG",
131: "BG",
136: "BG",
141: "BG",
146: "BG",
151: "BG",
156: "B",
161: "B",
166: "B",
171: "B",
176: "B",
181: "B",
186: "B",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
151: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "BG",
136: "BG",
141: "BG",
146: "BG",
151: "BG",
156: "BG",
161: "B",
166: "B",
171: "B",
176: "B",
181: "B",
186: "B",
191: "B",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "",
251: "",
},
156: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "BG",
141: "BG",
146: "BG",
151: "BG",
156: "BG",
161: "BG",
166: "B",
171: "B",
176: "B",
181: "B",
186: "B",
191: "B",
196: "B",
201: "B",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "",
236: "",
241: "",
246: "",
251: "",
},
161: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "BG",
141: "BG",
146: "BG",
151: "BG",
156: "BG",
161: "BG",
166: "BG",
171: "B",
176: "B",
181: "B",
186: "B",
191: "B",
196: "B",
201: "B",
206: "B",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "",
241: "",
246: "",
251: "",
},
166: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "BG",
146: "BG",
151: "BG",
156: "BG",
161: "BG",
166: "BG",
171: "BG",
176: "B",
181: "B",
186: "B",
191: "B",
196: "B",
201: "B",
206: "B",
211: "B",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "",
251: "",
},
171: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "BG",
151: "BG",
156: "BG",
161: "BG",
166: "BG",
171: "BG",
176: "BG",
181: "B",
186: "B",
191: "B",
196: "B",
201: "B",
206: "B",
211: "B",
216: "B",
221: "B",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "",
},
176: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "BG",
156: "BG",
161: "BG",
166: "BG",
171: "BG",
176: "BG",
181: "BG",
186: "B",
191: "B",
196: "B",
201: "B",
206: "B",
211: "B",
216: "B",
221: "B",
226: "B",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
181: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "BG",
161: "BG",
166: "BG",
171: "BG",
176: "BG",
181: "BG",
186: "BG",
191: "B",
196: "B",
201: "B",
206: "B",
211: "B",
216: "B",
221: "B",
226: "B",
231: "B",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
186: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "BG",
161: "BG",
166: "BG",
171: "BG",
176: "BG",
181: "BG",
186: "BG",
191: "BG",
196: "B",
201: "B",
206: "B",
211: "B",
216: "B",
221: "B",
226: "B",
231: "B",
236: "B",
241: "PB",
246: "",
251: "",
},
191: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "BG",
166: "BG",
171: "BG",
176: "BG",
181: "BG",
186: "BG",
191: "BG",
196: "BG",
201: "B",
206: "B",
211: "B",
216: "B",
221: "B",
226: "B",
231: "B",
236: "B",
241: "B",
246: "",
251: "",
},
196: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "BG",
171: "BG",
176: "BG",
181: "BG",
186: "BG",
191: "BG",
196: "BG",
201: "BG",
206: "B",
211: "B",
216: "B",
221: "B",
226: "B",
231: "B",
236: "B",
241: "B",
246: "B",
251: "",
},
201: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "BG",
176: "BG",
181: "BG",
186: "BG",
191: "BG",
196: "BG",
201: "BG",
206: "BG",
211: "B",
216: "B",
221: "B",
226: "B",
231: "B",
236: "B",
241: "B",
246: "B",
251: "B",
},
206: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "BG",
181: "BG",
186: "BG",
191: "BG",
196: "BG",
201: "BG",
206: "BG",
211: "BG",
216: "B",
221: "B",
226: "B",
231: "B",
236: "B",
241: "B",
246: "B",
251: "B",
},
211: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "BG",
181: "BG",
186: "BG",
191: "BG",
196: "BG",
201: "BG",
206: "BG",
211: "BG",
216: "BG",
221: "B",
226: "B",
231: "B",
236: "B",
241: "B",
246: "B",
251: "B",
},
216: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "BG",
186: "BG",
191: "BG",
196: "BG",
201: "BG",
206: "BG",
211: "BG",
216: "BG",
221: "BG",
226: "B",
231: "B",
236: "B",
241: "B",
246: "B",
251: "B",
},
221: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "BG",
191: "BG",
196: "BG",
201: "BG",
206: "BG",
211: "BG",
216: "BG",
221: "BG",
226: "BG",
231: "",
236: "",
241: "",
246: "",
251: "",
},
226: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "BG",
196: "BG",
201: "BG",
206: "BG",
211: "BG",
216: "BG",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
231: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "BG",
201: "BG",
206: "BG",
211: "BG",
216: "BG",
221: "BG",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
236: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "BG",
201: "BG",
206: "BG",
211: "BG",
216: "BG",
221: "BG",
226: "BG",
231: "",
236: "",
241: "",
246: "",
251: "",
},
241: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "BG",
206: "BG",
211: "BG",
216: "BG",
221: "BG",
226: "BG",
231: "BG",
236: "",
241: "",
246: "",
251: "",
},
246: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "BG",
211: "BG",
216: "BG",
221: "BG",
226: "BG",
231: "BG",
236: "BG",
241: "",
246: "",
251: "",
},
251: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "G",
66: "G",
71: "G",
76: "G",
81: "G",
86: "G",
91: "G",
96: "G",
101: "G",
106: "G",
111: "G",
116: "G",
121: "G",
126: "G",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "BG",
216: "BG",
221: "BG",
226: "BG",
231: "BG",
236: "BG",
241: "BG",
246: "",
251: "",
}}

export default PCCS_Color_Calc_R56
    