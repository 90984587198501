/* eslint-disable */
/**
 * シェーダーでよく使う機能をまとめたファイルです。
 * @author Nozomi Nohara / http://github.com/ics-nohara
 */
// language=GLSL
export const VERTEX_SHADER = `
varying vec2 vUv;
attribute vec2 aVertexPosition;
attribute vec2 aVertexTexcoord;
varying vec2 vTexcoord;

void main() {
  vTexcoord = uv;
  vUv = uv;
  // gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  gl_Position = vec4(position, 1.0);
}
`;

/*
export const VERTEX_SHADER = `
attribute vec2 aVertexPosition;
attribute vec2 aVertexTexcoord;
varying vec2 vTexcoord;

void main() {
    gl_Position = vec4(aVertexPosition, 0, 1);
    vTexcoord = uv;
}
`;
*/
