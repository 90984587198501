import {BackgroundMode, StrictMode} from "@/utils/judge/threshold/judge_data";

export const T_Threshold_E = {}
export const T_Threshold_L = {}
T_Threshold_E[StrictMode.Yurume.value] = {}
T_Threshold_L[StrictMode.Yurume.value] = {}
T_Threshold_E[StrictMode.Yurume.value] [BackgroundMode.Zentai.value] = {
    "R": 18, "YR": 21, "Y": 13, "GY": 16, "G": 18, "BG": 18, "B": 20, "PB": 16, "P": 15, "RP": 26, "N": 18, 
}
T_Threshold_L[StrictMode.Yurume.value] [BackgroundMode.Zentai.value] = {
    "R": 18, "YR": 20, "Y": 25, "GY": 18, "G": 21, "BG": 25, "B": 33, "PB": 18, "P": 18, "RP": 21, "N": 21, 
}
T_Threshold_E[StrictMode.Yurume.value] [BackgroundMode.An.value] = {
    "R": 15, "YR": 19, "Y": 12, "GY": 14, "G": 16, "BG": 15, "B": 16, "PB": 15, "P": 14, "RP": 17, "N": 15, 
}
T_Threshold_L[StrictMode.Yurume.value] [BackgroundMode.An.value] = {
    "R": 18, "YR": 20, "Y": 25, "GY": 18, "G": 21, "BG": 25, "B": 33, "PB": 18, "P": 18, "RP": 21, "N": 21, 
}
T_Threshold_E[StrictMode.Yurume.value] [BackgroundMode.Chu.value] = {
    "R": 15, "YR": 18, "Y": 13, "GY": 15, "G": 17, "BG": 17, "B": 17, "PB": 16, "P": 14, "RP": 17, "N": 15, 
}
T_Threshold_L[StrictMode.Yurume.value] [BackgroundMode.Chu.value] = {
    "R": 10, "YR": 11, "Y": 10, "GY": 11, "G": 10, "BG": 10, "B": 11, "PB": 10, "P": 10, "RP": 10, "N": 10, 
}
T_Threshold_E[StrictMode.Yurume.value] [BackgroundMode.Mei.value] = {
    "R": 18, "YR": 21, "Y": 12, "GY": 16, "G": 18, "BG": 18, "B": 20, "PB": 16, "P": 15, "RP": 26, "N": 18, 
}
T_Threshold_L[StrictMode.Yurume.value] [BackgroundMode.Mei.value] = {
    "R": 14, "YR": 14, "Y": 14, "GY": 16, "G": 20, "BG": 17, "B": 14, "PB": 16, "P": 17, "RP": 18, "N": 16, 
}
T_Threshold_E[StrictMode.Futu.value] = {}
T_Threshold_L[StrictMode.Futu.value] = {}
T_Threshold_E[StrictMode.Futu.value] [BackgroundMode.Zentai.value] = {
    "R": 18, "YR": 21, "Y": 13, "GY": 16, "G": 18, "BG": 18, "B": 20, "PB": 16, "P": 15, "RP": 26, "N": 18, 
}
T_Threshold_L[StrictMode.Futu.value] [BackgroundMode.Zentai.value] = {
    "R": 18, "YR": 20, "Y": 25, "GY": 18, "G": 21, "BG": 25, "B": 33, "PB": 18, "P": 18, "RP": 21, "N": 21, 
}
T_Threshold_E[StrictMode.Futu.value] [BackgroundMode.An.value] = {
    "R": 15, "YR": 19, "Y": 12, "GY": 14, "G": 16, "BG": 15, "B": 16, "PB": 15, "P": 14, "RP": 17, "N": 15, 
}
T_Threshold_L[StrictMode.Futu.value] [BackgroundMode.An.value] = {
    "R": 18, "YR": 20, "Y": 25, "GY": 18, "G": 21, "BG": 25, "B": 33, "PB": 18, "P": 18, "RP": 21, "N": 21, 
}
T_Threshold_E[StrictMode.Futu.value] [BackgroundMode.Chu.value] = {
    "R": 15, "YR": 18, "Y": 13, "GY": 15, "G": 17, "BG": 17, "B": 17, "PB": 16, "P": 14, "RP": 17, "N": 15, 
}
T_Threshold_L[StrictMode.Futu.value] [BackgroundMode.Chu.value] = {
    "R": 10, "YR": 11, "Y": 10, "GY": 11, "G": 10, "BG": 10, "B": 11, "PB": 10, "P": 10, "RP": 10, "N": 10, 
}
T_Threshold_E[StrictMode.Futu.value] [BackgroundMode.Mei.value] = {
    "R": 18, "YR": 21, "Y": 12, "GY": 16, "G": 18, "BG": 18, "B": 20, "PB": 16, "P": 15, "RP": 26, "N": 18, 
}
T_Threshold_L[StrictMode.Futu.value] [BackgroundMode.Mei.value] = {
    "R": 14, "YR": 14, "Y": 14, "GY": 16, "G": 20, "BG": 17, "B": 14, "PB": 16, "P": 17, "RP": 18, "N": 16, 
}
T_Threshold_E[StrictMode.Kitume.value] = {}
T_Threshold_L[StrictMode.Kitume.value] = {}
T_Threshold_E[StrictMode.Kitume.value] [BackgroundMode.Zentai.value] = {
    "R": 18, "YR": 21, "Y": 13, "GY": 16, "G": 18, "BG": 18, "B": 20, "PB": 16, "P": 15, "RP": 26, "N": 18, 
}
T_Threshold_L[StrictMode.Kitume.value] [BackgroundMode.Zentai.value] = {
    "R": 18, "YR": 20, "Y": 25, "GY": 18, "G": 21, "BG": 25, "B": 33, "PB": 18, "P": 18, "RP": 21, "N": 21, 
}
T_Threshold_E[StrictMode.Kitume.value] [BackgroundMode.An.value] = {
    "R": 15, "YR": 19, "Y": 12, "GY": 14, "G": 16, "BG": 15, "B": 16, "PB": 15, "P": 14, "RP": 17, "N": 15, 
}
T_Threshold_L[StrictMode.Kitume.value] [BackgroundMode.An.value] = {
    "R": 18, "YR": 20, "Y": 25, "GY": 18, "G": 21, "BG": 25, "B": 33, "PB": 18, "P": 18, "RP": 21, "N": 21, 
}
T_Threshold_E[StrictMode.Kitume.value] [BackgroundMode.Chu.value] = {
    "R": 15, "YR": 18, "Y": 13, "GY": 15, "G": 17, "BG": 17, "B": 17, "PB": 16, "P": 14, "RP": 17, "N": 15, 
}
T_Threshold_L[StrictMode.Kitume.value] [BackgroundMode.Chu.value] = {
    "R": 10, "YR": 11, "Y": 10, "GY": 11, "G": 10, "BG": 10, "B": 11, "PB": 10, "P": 10, "RP": 10, "N": 10, 
}
T_Threshold_E[StrictMode.Kitume.value] [BackgroundMode.Mei.value] = {
    "R": 18, "YR": 21, "Y": 12, "GY": 16, "G": 18, "BG": 18, "B": 20, "PB": 16, "P": 15, "RP": 26, "N": 18, 
}
T_Threshold_L[StrictMode.Kitume.value] [BackgroundMode.Mei.value] = {
    "R": 14, "YR": 14, "Y": 14, "GY": 16, "G": 20, "BG": 17, "B": 14, "PB": 16, "P": 17, "RP": 18, "N": 16, 
}
