const PCCS_Color_Calc_R236 = {
    1: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "RP",
111: "",
116: "",
121: "",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
6: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "",
111: "",
116: "",
121: "",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
11: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "",
106: "",
111: "",
116: "",
121: "",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
16: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "",
101: "",
106: "",
111: "",
116: "",
121: "",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
21: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "",
91: "",
96: "",
101: "",
106: "",
111: "",
116: "",
121: "",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
26: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "",
106: "",
111: "",
116: "",
121: "",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
31: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "",
51: "",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "RP",
116: "RP",
121: "",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
36: {1: "",
6: "",
11: "",
16: "",
21: "",
26: "",
31: "",
36: "",
41: "",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
41: {1: "",
6: "",
11: "",
16: "",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
46: {1: "",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
51: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
56: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
61: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
66: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
71: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
76: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
81: {1: "YR",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
86: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
91: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
96: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "P",
216: "P",
221: "P",
226: "P",
231: "",
236: "",
241: "",
246: "",
251: "",
},
101: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
106: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "R",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
111: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "R",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
116: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "R",
136: "R",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
121: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "R",
136: "R",
141: "R",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
126: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "R",
136: "R",
141: "R",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
131: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "R",
136: "R",
141: "R",
146: "R",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
136: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "R",
136: "R",
141: "R",
146: "R",
151: "R",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
141: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "R",
116: "R",
121: "R",
126: "R",
131: "R",
136: "R",
141: "R",
146: "R",
151: "R",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
146: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "R",
126: "R",
131: "R",
136: "R",
141: "R",
146: "R",
151: "R",
156: "R",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
151: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "R",
131: "R",
136: "R",
141: "R",
146: "R",
151: "R",
156: "R",
161: "R",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
156: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "YR",
131: "R",
136: "R",
141: "R",
146: "R",
151: "R",
156: "R",
161: "R",
166: "RP",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "RP",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
161: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "YR",
131: "YR",
136: "YR",
141: "R",
146: "R",
151: "R",
156: "R",
161: "R",
166: "R",
171: "RP",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "RP",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
166: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "YR",
131: "YR",
136: "YR",
141: "YR",
146: "R",
151: "R",
156: "R",
161: "R",
166: "R",
171: "R",
176: "RP",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "RP",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "",
},
171: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "YR",
131: "YR",
136: "YR",
141: "YR",
146: "YR",
151: "R",
156: "R",
161: "R",
166: "R",
171: "R",
176: "R",
181: "RP",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "RP",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
176: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "YR",
131: "YR",
136: "YR",
141: "YR",
146: "YR",
151: "YR",
156: "YR",
161: "R",
166: "R",
171: "R",
176: "R",
181: "R",
186: "RP",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "RP",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
181: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "YR",
131: "YR",
136: "YR",
141: "YR",
146: "YR",
151: "YR",
156: "YR",
161: "YR",
166: "R",
171: "R",
176: "R",
181: "R",
186: "R",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "RP",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "",
},
186: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "YR",
121: "YR",
126: "YR",
131: "YR",
136: "YR",
141: "YR",
146: "YR",
151: "YR",
156: "YR",
161: "YR",
166: "YR",
171: "R",
176: "R",
181: "R",
186: "R",
191: "RP",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "RP",
221: "RP",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
191: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "YR",
131: "YR",
136: "YR",
141: "YR",
146: "YR",
151: "YR",
156: "YR",
161: "YR",
166: "YR",
171: "YR",
176: "YR",
181: "R",
186: "R",
191: "R",
196: "RP",
201: "RP",
206: "RP",
211: "RP",
216: "RP",
221: "RP",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
196: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "YR",
146: "YR",
151: "YR",
156: "YR",
161: "YR",
166: "YR",
171: "YR",
176: "YR",
181: "YR",
186: "R",
191: "R",
196: "R",
201: "RP",
206: "RP",
211: "RP",
216: "RP",
221: "RP",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
201: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "Y",
146: "Y",
151: "Y",
156: "YR",
161: "YR",
166: "YR",
171: "YR",
176: "YR",
181: "YR",
186: "YR",
191: "R",
196: "R",
201: "R",
206: "RP",
211: "RP",
216: "RP",
221: "RP",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
206: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "Y",
146: "Y",
151: "Y",
156: "Y",
161: "Y",
166: "YR",
171: "YR",
176: "YR",
181: "YR",
186: "YR",
191: "YR",
196: "YR",
201: "R",
206: "R",
211: "RP",
216: "RP",
221: "RP",
226: "RP",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
211: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "Y",
146: "Y",
151: "Y",
156: "Y",
161: "Y",
166: "Y",
171: "Y",
176: "YR",
181: "YR",
186: "YR",
191: "YR",
196: "YR",
201: "YR",
206: "R",
211: "R",
216: "RP",
221: "RP",
226: "RP",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
216: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "Y",
146: "Y",
151: "Y",
156: "Y",
161: "Y",
166: "Y",
171: "Y",
176: "Y",
181: "Y",
186: "Y",
191: "YR",
196: "YR",
201: "YR",
206: "YR",
211: "R",
216: "R",
221: "RP",
226: "RP",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
221: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "Y",
146: "Y",
151: "Y",
156: "Y",
161: "Y",
166: "Y",
171: "Y",
176: "Y",
181: "Y",
186: "Y",
191: "Y",
196: "Y",
201: "YR",
206: "YR",
211: "YR",
216: "YR",
221: "R",
226: "RP",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
226: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "Y",
146: "Y",
151: "Y",
156: "Y",
161: "Y",
166: "Y",
171: "Y",
176: "Y",
181: "Y",
186: "Y",
191: "Y",
196: "Y",
201: "Y",
206: "Y",
211: "Y",
216: "YR",
221: "YR",
226: "R",
231: "RP",
236: "P",
241: "P",
246: "P",
251: "P",
},
231: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "Y",
156: "Y",
161: "Y",
166: "Y",
171: "Y",
176: "Y",
181: "Y",
186: "Y",
191: "Y",
196: "Y",
201: "Y",
206: "Y",
211: "Y",
216: "Y",
221: "Y",
226: "YR",
231: "R",
236: "P",
241: "P",
246: "P",
251: "P",
},
236: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "GY",
186: "GY",
191: "GY",
196: "GY",
201: "GY",
206: "GY",
211: "GY",
216: "GY",
221: "GY",
226: "GY",
231: "GY",
236: "N",
241: "PB",
246: "PB",
251: "PB",
},
241: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "GY",
186: "GY",
191: "GY",
196: "GY",
201: "GY",
206: "GY",
211: "GY",
216: "GY",
221: "GY",
226: "GY",
231: "GY",
236: "G",
241: "B",
246: "PB",
251: "PB",
},
246: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "GY",
186: "GY",
191: "GY",
196: "GY",
201: "GY",
206: "GY",
211: "GY",
216: "GY",
221: "GY",
226: "GY",
231: "GY",
236: "G",
241: "G",
246: "B",
251: "PB",
},
251: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "GY",
186: "GY",
191: "GY",
196: "GY",
201: "GY",
206: "GY",
211: "GY",
216: "GY",
221: "GY",
226: "GY",
231: "GY",
236: "G",
241: "G",
246: "BG",
251: "B",
}}

export default PCCS_Color_Calc_R236
    