const PCCS_Color_Calc_R186 = {
    1: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
6: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
11: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
16: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
21: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
26: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
31: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
36: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
41: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "",
},
46: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
51: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
56: {1: "YR",
6: "YR",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "",
236: "",
241: "",
246: "",
251: "",
},
61: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
66: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
71: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
76: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
81: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "",
236: "",
241: "",
246: "",
251: "",
},
86: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
91: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "",
236: "",
241: "",
246: "",
251: "",
},
96: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
101: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "R",
81: "R",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
106: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "R",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "RP",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
111: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "R",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "RP",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
116: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "R",
101: "R",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
121: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "R",
111: "R",
116: "R",
121: "R",
126: "R",
131: "RP",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
126: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "R",
116: "R",
121: "R",
126: "R",
131: "R",
136: "RP",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
131: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "R",
121: "R",
126: "R",
131: "R",
136: "R",
141: "RP",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
136: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "R",
131: "R",
136: "R",
141: "R",
146: "RP",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
141: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "YR",
131: "R",
136: "R",
141: "R",
146: "R",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
146: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "YR",
101: "YR",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "YR",
131: "YR",
136: "R",
141: "R",
146: "R",
151: "RP",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
151: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "YR",
111: "YR",
116: "YR",
121: "YR",
126: "YR",
131: "YR",
136: "YR",
141: "R",
146: "R",
151: "R",
156: "RP",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
156: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "YR",
126: "YR",
131: "YR",
136: "YR",
141: "YR",
146: "YR",
151: "R",
156: "R",
161: "RP",
166: "RP",
171: "RP",
176: "RP",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
161: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "YR",
136: "YR",
141: "YR",
146: "YR",
151: "YR",
156: "R",
161: "R",
166: "RP",
171: "RP",
176: "RP",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
166: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "Y",
146: "YR",
151: "YR",
156: "YR",
161: "R",
166: "R",
171: "RP",
176: "RP",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
171: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "Y",
146: "Y",
151: "Y",
156: "YR",
161: "YR",
166: "YR",
171: "R",
176: "RP",
181: "RP",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
176: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "Y",
146: "Y",
151: "Y",
156: "Y",
161: "Y",
166: "YR",
171: "YR",
176: "R",
181: "RP",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
181: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "Y",
136: "Y",
141: "Y",
146: "Y",
151: "Y",
156: "Y",
161: "Y",
166: "Y",
171: "Y",
176: "YR",
181: "R",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
186: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "GY",
186: "N",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "",
},
191: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "GY",
186: "G",
191: "B",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
196: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "GY",
186: "G",
191: "G",
196: "B",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
201: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "GY",
186: "G",
191: "G",
196: "BG",
201: "B",
206: "B",
211: "PB",
216: "PB",
221: "PB",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
206: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "GY",
186: "G",
191: "G",
196: "G",
201: "BG",
206: "B",
211: "B",
216: "B",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
211: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "GY",
186: "G",
191: "G",
196: "G",
201: "G",
206: "BG",
211: "B",
216: "B",
221: "B",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
216: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "BG",
211: "BG",
216: "B",
221: "B",
226: "B",
231: "",
236: "",
241: "",
246: "",
251: "",
},
221: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "BG",
216: "BG",
221: "B",
226: "B",
231: "B",
236: "B",
241: "",
246: "",
251: "",
},
226: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "G",
216: "BG",
221: "BG",
226: "B",
231: "B",
236: "B",
241: "B",
246: "",
251: "",
},
231: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "G",
216: "BG",
221: "BG",
226: "BG",
231: "B",
236: "B",
241: "B",
246: "B",
251: "B",
},
236: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "GY",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "G",
216: "G",
221: "BG",
226: "BG",
231: "BG",
236: "B",
241: "B",
246: "B",
251: "B",
},
241: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "G",
216: "G",
221: "G",
226: "BG",
231: "BG",
236: "BG",
241: "B",
246: "B",
251: "B",
},
246: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "G",
216: "G",
221: "G",
226: "BG",
231: "BG",
236: "BG",
241: "BG",
246: "BG",
251: "B",
},
251: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "GY",
146: "GY",
151: "GY",
156: "GY",
161: "GY",
166: "GY",
171: "GY",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "G",
216: "G",
221: "G",
226: "G",
231: "BG",
236: "BG",
241: "BG",
246: "BG",
251: "BG",
}}

export default PCCS_Color_Calc_R186
    