/* eslint-disable */
import { VERTEX_SHADER } from "./ShaderUtil.js";

// language=GLSL
const FRAGMENT_SHADER = `

varying vec2 vUv;
uniform sampler2D tDiffuse;

void main() {
  vec4 color = texture2D(tDiffuse, vUv);
  color = vec4(color.r*0.8, color.g*0.8, color.b*0.6, 1.0);
  gl_FragColor = vec4(color);
}
`;

export class CataractShader {
  constructor() {
    this.vertexShader = VERTEX_SHADER;
    this.fragmentShader = FRAGMENT_SHADER;
  }
}
