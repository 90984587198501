import Vue from 'vue'
import Vuex from 'vuex'
import ColorBarImage from '../assets/colorbar.png'
import {BackgroundMode, StrictMode} from "@/utils/judge/threshold/judge_data";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mode: 0,
    strict: StrictMode.Futu.value,
    background: BackgroundMode.Zentai.value,
    colorDisplayMode: "lab",
    imageFile: ColorBarImage,
    clickCount: 0,
  },
  getters: {
    mode(state) {
      // 使用可否リストを取得
      return state.mode
    },
    strict(state) {
      return state.strict
    },
    background(state) {
      return state.background
    },
    colorDisplayMode(state) {
      return state.colorDisplayMode
    },
    imageFile(state) {
      return state.imageFile
    },
    clickCount(state) {
      return state.clickCount
    },
  },
  mutations: {
    setMode(state, value) {
      state.mode = value
    },
    setStrict(state, value) {
      state.strict = value
    },
    setBackground(state, value) {
      state.background = value
    },
    setColorDisplayMode(state, value) {
      state.colorDisplayMode = value
    },
    setImageFile(state, value) {
      state.imageFile = value
      state.clickCount = 0
    },
    setClickCount(state, value) {
      state.clickCount = value
    },
  },
  actions: {},
  modules: {}
})
