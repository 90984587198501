import {BackgroundMode, StrictMode} from "@/utils/judge/threshold/judge_data";

export const D_Threshold_E = {}
export const D_Threshold_L = {}
D_Threshold_E[StrictMode.Yurume.value] = {}
D_Threshold_L[StrictMode.Yurume.value] = {}
D_Threshold_E[StrictMode.Yurume.value] [BackgroundMode.Zentai.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Yurume.value] [BackgroundMode.Zentai.value] = {
    "R": 14, "YR": 13, "Y": 14, "GY": 17, "G": 16, "BG": 23, "B": 24, "PB": 16, "P": 14, "RP": 15, "N": 16, 
}
D_Threshold_E[StrictMode.Yurume.value] [BackgroundMode.An.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Yurume.value] [BackgroundMode.An.value] = {
    "R": 14, "YR": 11, "Y": 14, "GY": 17, "G": 16, "BG": 23, "B": 24, "PB": 16, "P": 14, "RP": 14, "N": 16, 
}
D_Threshold_E[StrictMode.Yurume.value] [BackgroundMode.Chu.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Yurume.value] [BackgroundMode.Chu.value] = {
    "R": 10, "YR": 9, "Y": 10, "GY": 11, "G": 10, "BG": 11, "B": 11, "PB": 10, "P": 9, "RP": 10, "N": 10, 
}
D_Threshold_E[StrictMode.Yurume.value] [BackgroundMode.Mei.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Yurume.value] [BackgroundMode.Mei.value] = {
    "R": 14, "YR": 13, "Y": 12, "GY": 13, "G": 13, "BG": 14, "B": 15, "PB": 15, "P": 13, "RP": 15, "N": 13, 
}
D_Threshold_E[StrictMode.Futu.value] = {}
D_Threshold_L[StrictMode.Futu.value] = {}
D_Threshold_E[StrictMode.Futu.value] [BackgroundMode.Zentai.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Futu.value] [BackgroundMode.Zentai.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_E[StrictMode.Futu.value] [BackgroundMode.An.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Futu.value] [BackgroundMode.An.value] = {
    "R": 15, "YR": 12, "Y": 15, "GY": 18, "G": 17, "BG": 24, "B": 25, "PB": 17, "P": 15, "RP": 15, "N": 17, 
}
D_Threshold_E[StrictMode.Futu.value] [BackgroundMode.Chu.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Futu.value] [BackgroundMode.Chu.value] = {
    "R": 10, "YR": 10, "Y": 10, "GY": 11, "G": 11, "BG": 11, "B": 12, "PB": 11, "P": 10, "RP": 11, "N": 10, 
}
D_Threshold_E[StrictMode.Futu.value] [BackgroundMode.Mei.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Futu.value] [BackgroundMode.Mei.value] = {
    "R": 15, "YR": 13, "Y": 13, "GY": 14, "G": 14, "BG": 15, "B": 16, "PB": 15, "P": 14, "RP": 16, "N": 14, 
}
D_Threshold_E[StrictMode.Kitume.value] = {}
D_Threshold_L[StrictMode.Kitume.value] = {}
D_Threshold_E[StrictMode.Kitume.value] [BackgroundMode.Zentai.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Kitume.value] [BackgroundMode.Zentai.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_E[StrictMode.Kitume.value] [BackgroundMode.An.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Kitume.value] [BackgroundMode.An.value] = {
    "R": 16, "YR": 13, "Y": 16, "GY": 20, "G": 19, "BG": 26, "B": 27, "PB": 18, "P": 16, "RP": 16, "N": 18, 
}
D_Threshold_E[StrictMode.Kitume.value] [BackgroundMode.Chu.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Kitume.value] [BackgroundMode.Chu.value] = {
    "R": 11, "YR": 11, "Y": 11, "GY": 12, "G": 12, "BG": 12, "B": 12, "PB": 12, "P": 11, "RP": 12, "N": 11, 
}
D_Threshold_E[StrictMode.Kitume.value] [BackgroundMode.Mei.value] = {
    "R": 0, "YR": 0, "Y": 0, "GY": 0, "G": 0, "BG": 0, "B": 0, "PB": 0, "P": 0, "RP": 0, "N": 0, 
}
D_Threshold_L[StrictMode.Kitume.value] [BackgroundMode.Mei.value] = {
    "R": 16, "YR": 14, "Y": 14, "GY": 15, "G": 14, "BG": 16, "B": 17, "PB": 16, "P": 15, "RP": 17, "N": 15, 
}
