const PCCS_Color_Calc_R141 = {
    1: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "RP",
71: "RP",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "",
236: "",
241: "",
246: "",
251: "",
},
6: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "RP",
71: "RP",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "",
236: "",
241: "",
246: "",
251: "",
},
11: {1: "R",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "RP",
71: "RP",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
16: {1: "YR",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "RP",
71: "RP",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
21: {1: "YR",
6: "R",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "RP",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
26: {1: "YR",
6: "YR",
11: "R",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "RP",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
31: {1: "YR",
6: "YR",
11: "YR",
16: "R",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "RP",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
36: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "R",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "RP",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
41: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "R",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
46: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "R",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
51: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "R",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "RP",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
56: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "R",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
61: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "R",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "RP",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "",
236: "",
241: "",
246: "",
251: "",
},
66: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "R",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "RP",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
71: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "R",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "",
251: "",
},
76: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "R",
66: "R",
71: "R",
76: "R",
81: "R",
86: "R",
91: "RP",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
81: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "R",
76: "R",
81: "R",
86: "R",
91: "R",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
86: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "R",
81: "R",
86: "R",
91: "R",
96: "RP",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "",
},
91: {1: "YR",
6: "YR",
11: "YR",
16: "YR",
21: "YR",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "R",
86: "R",
91: "R",
96: "R",
101: "RP",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "P",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "",
241: "",
246: "",
251: "",
},
96: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "YR",
31: "YR",
36: "YR",
41: "YR",
46: "YR",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "R",
91: "R",
96: "R",
101: "R",
106: "RP",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
101: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "YR",
56: "YR",
61: "YR",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "R",
96: "R",
101: "R",
106: "R",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
106: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "YR",
71: "YR",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "R",
106: "R",
111: "RP",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "",
236: "",
241: "",
246: "",
251: "",
},
111: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "YR",
81: "YR",
86: "YR",
91: "YR",
96: "YR",
101: "YR",
106: "R",
111: "R",
116: "RP",
121: "RP",
126: "RP",
131: "RP",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "",
246: "",
251: "",
},
116: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "YR",
96: "YR",
101: "YR",
106: "YR",
111: "R",
116: "R",
121: "RP",
126: "RP",
131: "RP",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "",
},
121: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "YR",
106: "YR",
111: "YR",
116: "R",
121: "R",
126: "RP",
131: "RP",
136: "P",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "P",
251: "P",
},
126: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "YR",
116: "YR",
121: "YR",
126: "R",
131: "RP",
136: "RP",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "P",
231: "P",
236: "P",
241: "P",
246: "PB",
251: "",
},
131: {1: "Y",
6: "Y",
11: "Y",
16: "Y",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "YR",
126: "YR",
131: "R",
136: "RP",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "P",
201: "P",
206: "P",
211: "P",
216: "P",
221: "P",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
136: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "Y",
26: "Y",
31: "Y",
36: "Y",
41: "Y",
46: "Y",
51: "Y",
56: "Y",
61: "Y",
66: "Y",
71: "Y",
76: "Y",
81: "Y",
86: "Y",
91: "Y",
96: "Y",
101: "Y",
106: "Y",
111: "Y",
116: "Y",
121: "Y",
126: "Y",
131: "YR",
136: "R",
141: "P",
146: "P",
151: "P",
156: "P",
161: "P",
166: "P",
171: "P",
176: "P",
181: "P",
186: "P",
191: "P",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "",
241: "",
246: "",
251: "",
},
141: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "N",
146: "PB",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "",
231: "",
236: "",
241: "",
246: "",
251: "",
},
146: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "G",
146: "BG",
151: "PB",
156: "PB",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "",
241: "",
246: "",
251: "",
},
151: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "G",
146: "G",
151: "BG",
156: "B",
161: "PB",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "",
246: "",
251: "",
},
156: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "G",
146: "G",
151: "BG",
156: "BG",
161: "B",
166: "PB",
171: "PB",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "",
},
161: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "G",
146: "G",
151: "G",
156: "BG",
161: "BG",
166: "B",
171: "B",
176: "PB",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
166: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "G",
146: "G",
151: "G",
156: "G",
161: "BG",
166: "BG",
171: "B",
176: "B",
181: "PB",
186: "PB",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
171: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "G",
146: "G",
151: "G",
156: "G",
161: "BG",
166: "BG",
171: "BG",
176: "B",
181: "B",
186: "B",
191: "PB",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
176: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "GY",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "BG",
171: "BG",
176: "BG",
181: "B",
186: "B",
191: "B",
196: "PB",
201: "PB",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "",
241: "",
246: "",
251: "",
},
181: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "BG",
176: "BG",
181: "BG",
186: "B",
191: "B",
196: "B",
201: "B",
206: "PB",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "",
246: "",
251: "",
},
186: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "BG",
176: "BG",
181: "BG",
186: "BG",
191: "B",
196: "B",
201: "B",
206: "B",
211: "PB",
216: "PB",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "",
251: "",
},
191: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "BG",
181: "BG",
186: "BG",
191: "BG",
196: "B",
201: "B",
206: "B",
211: "B",
216: "B",
221: "PB",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "",
},
196: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "BG",
186: "BG",
191: "BG",
196: "BG",
201: "B",
206: "B",
211: "B",
216: "B",
221: "B",
226: "PB",
231: "PB",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
201: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "BG",
191: "BG",
196: "BG",
201: "BG",
206: "B",
211: "B",
216: "B",
221: "B",
226: "B",
231: "B",
236: "PB",
241: "PB",
246: "PB",
251: "PB",
},
206: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "BG",
191: "BG",
196: "BG",
201: "BG",
206: "BG",
211: "B",
216: "B",
221: "B",
226: "B",
231: "B",
236: "B",
241: "PB",
246: "PB",
251: "PB",
},
211: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "GY",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "BG",
196: "BG",
201: "BG",
206: "BG",
211: "BG",
216: "B",
221: "B",
226: "B",
231: "B",
236: "B",
241: "B",
246: "",
251: "",
},
216: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "BG",
201: "BG",
206: "BG",
211: "BG",
216: "BG",
221: "B",
226: "B",
231: "B",
236: "B",
241: "B",
246: "B",
251: "",
},
221: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "BG",
201: "BG",
206: "BG",
211: "BG",
216: "BG",
221: "BG",
226: "B",
231: "B",
236: "B",
241: "B",
246: "B",
251: "B",
},
226: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "BG",
206: "BG",
211: "BG",
216: "BG",
221: "BG",
226: "BG",
231: "B",
236: "B",
241: "B",
246: "B",
251: "B",
},
231: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "BG",
211: "BG",
216: "BG",
221: "BG",
226: "BG",
231: "BG",
236: "B",
241: "B",
246: "B",
251: "B",
},
236: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "BG",
216: "BG",
221: "BG",
226: "BG",
231: "BG",
236: "BG",
241: "B",
246: "B",
251: "B",
},
241: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "BG",
216: "BG",
221: "BG",
226: "BG",
231: "BG",
236: "BG",
241: "BG",
246: "B",
251: "B",
},
246: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "G",
216: "BG",
221: "BG",
226: "BG",
231: "BG",
236: "BG",
241: "BG",
246: "BG",
251: "B",
},
251: {1: "GY",
6: "GY",
11: "GY",
16: "GY",
21: "GY",
26: "GY",
31: "GY",
36: "GY",
41: "GY",
46: "GY",
51: "GY",
56: "GY",
61: "GY",
66: "GY",
71: "GY",
76: "GY",
81: "GY",
86: "GY",
91: "GY",
96: "GY",
101: "GY",
106: "GY",
111: "GY",
116: "GY",
121: "GY",
126: "GY",
131: "G",
136: "G",
141: "G",
146: "G",
151: "G",
156: "G",
161: "G",
166: "G",
171: "G",
176: "G",
181: "G",
186: "G",
191: "G",
196: "G",
201: "G",
206: "G",
211: "G",
216: "G",
221: "BG",
226: "BG",
231: "BG",
236: "BG",
241: "BG",
246: "BG",
251: "BG",
}}

export default PCCS_Color_Calc_R141
    