export const StrictMode = Object.freeze({
  Yurume: {
    label: "ゆるめ",
    value: 0
  },
  Futu: {
    label: "ふつう",
    value: 1
  },
  Kitume: {
    label: "きつめ",
    value: 2
  }
})

export const BackgroundMode = Object.freeze({
  Mei: {
    label: "明",
    value: 0
  },
  Chu: {
    label: "中",
    value: 1
  },
  An: {
    label: "暗",
    value: 2
  },
  Zentai: {
    label: "全体",
    value: 3
  }
})
