import {initializeApp} from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDWbY_JX80zSna9KMbNdzjRYeyW86a9pRc",
  authDomain: "dp-auth-e846f.firebaseapp.com",
  projectId: "dp-auth-e846f",
  storageBucket: "dp-auth-e846f.appspot.com",
  messagingSenderId: "565557280252",
  appId: "1:565557280252:web:f219158cc673be3fd64679"
};
const app = initializeApp(firebaseConfig);
export default app
